import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/Logo_Université_de_Fianarantsoa.png';
import '../assets/css/header.css';
import { ExpandLessRounded } from "@mui/icons-material";

function Header() {
    const [activeLink, setActiveLink] = useState('Accueil');
    const [showScrollButton, setShowScrollButton] = useState(false);

    const redirectToContact = () => {
        window.location.href = 'https://www.univ-fianarantsoa.mg/Contact';
    };

    const handleLinkClick = (link, id) => {
        setActiveLink(link);
        scrollToSection(id);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const scrollToSection = (id) => {
        const section = document.querySelector(id);
        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className='flex items-center justify-between p-4'>
            <div className='flex items-center'>
                <img src={logo} alt='Logo' className='h-15 w-12'/>
            </div>
            <div className='flex space-x-14'>
                <Link
                    to='/'
                    className={`text-3xl font-bold ${activeLink === 'Accueil' ? 'text-custom-green' : 'text-black'} hover:text-custom-green`}
                    onClick={() => handleLinkClick('Accueil', '#')}
                >
                    Accueil
                </Link>
                <a
                    href='#Ecole'
                    className={`text-3xl font-bold ${activeLink === 'École' ? 'text-custom-green' : 'text-black'} hover:text-custom-green`}
                    onClick={(e) => { e.preventDefault(); handleLinkClick('École', '#Ecole'); }}
                >
                    École
                </a>
                <a
                    href='#Faculté'
                    className={`text-3xl font-bold ${activeLink === 'Faculté' ? 'text-custom-green' : 'text-black'} hover:text-custom-green`}
                    onClick={(e) => { e.preventDefault(); handleLinkClick('Faculté', '#Faculté'); }}
                >
                    Faculté
                </a>
                <a
                    href='#Institut'
                    className={`text-3xl font-bold ${activeLink === 'Institut' ? 'text-custom-green' : 'text-black'} hover:text-custom-green`}
                    onClick={(e) => { e.preventDefault(); handleLinkClick('Institut', '#Institut'); }}
                >
                    Institut
                </a>
            </div>
            <div>
                <button
                    onClick={redirectToContact}
                    className='text-white font-bold py-3 px-6 rounded-[41px] hover:bg-green-700 active:bg-green-900'
                    style={{ backgroundColor: '#008D6C' }}
                >
                    Contactez-nous
                </button>
                {showScrollButton && (
                    <button
                        onClick={scrollToTop}
                        className='fixed bottom-10 right-10 text-white py-2 px-2 rounded-md shadow-lg transition-all duration-300'
                        style={{ backgroundColor: '#008D6C' }}
                    >
                        <ExpandLessRounded />
                    </button>
                )}
            </div>
        </div>
    );
}

export default Header;
