import React from 'react';
import {useNavigate} from 'react-router-dom';
import Search from '../components/search';
import Carrousel from '../components/carrousel';
import Devise from '../components/devise';
import President from '../components/president';
import Etablissement from '../components/etablissement';
import Title from '../components/title';

function Home() {
    const navigate = useNavigate();

    const handleSearch = (searchTerm) => {
        navigate(`/result?search=${searchTerm}`);
    };

    return (
        <div className='container flex flex-col items-center relative'>
            <Title/>
            <Search onSearch={handleSearch}/>
            <Carrousel/>
            <Devise/>
            <President/>
            <Etablissement/>
        </div>
    );
}

export default Home;
