import React from 'react';
import {FacebookRounded, LocationOnRounded , EmailRounded, FeedRounded, LocalPhoneRounded, PlayArrowRounded} from '@mui/icons-material';

function Footer() {
    return (
        <div className='w-full bg-green-100 py-32 flex justify-around mt-20 text-white'
             style={{backgroundColor: '#181D38'}}>
            <div className='mx-20'>
                <h2 className='text-2xl font-bold mb-4'>Contact</h2>
                <p className='mb-3'>Restez à jour sur nos nouvelles</p>
                <p className='mb-3'>
                    <LocationOnRounded />
                    <span> BP 1264 Andrainjato | 301 - FIANARANTSOA</span>
                </p>
                <p className='mb-3'>
                    <LocalPhoneRounded />
                    <span> (+261)20.75.508.02 | (+261)20.75.513.25 </span>
                </p>
                <p className='mb-3'>
                    <EmailRounded />
                    <span> contact@univ-fianarantsoa.mg </span>
                </p>
                <a href='https://web.facebook.com/univfianarantsoa/?_rdc=1&_rdr' target='_blank'
                   rel='noopener noreferrer'
                   className='transition duration-300 ease-in-out hover:text-blue-500'>
                    <FacebookRounded fontSize='large'/>
                </a>
            </div>
            <div className='mx-20'>
                <h2 className='text-2xl font-bold mb-4'>Radio ROFIA</h2>
                <p>Ecoutez la radio de l'université de Fianarantsoa</p>
                <div className='relative mt-4'>
                    <input type='text' placeholder='91.4MHz' disabled={true}
                           className='bg-gray-50 w-96 px-4 py-4 pr-12'/>
                    <a href='https://www.univ-fianarantsoa.mg/Rofia' target='_blank'>
                        <button className='absolute right-0 top-0 bottom-0 text-black px-5 py-3 bg-green-100'>
                            <PlayArrowRounded />
                        </button>
                    </a>
                </div>
            </div>

            <div className='mx-20'>
                <h2 className='text-2xl font-bold mb-4'>ROFIA Magazine</h2>
                <p>Emission périodique sur des sujets d'actualité</p>
                <div className='relative mt-4'>
                    <input type='text' placeholder='Emission périodique de ROFIA' disabled={true}
                           className='bg-gray-50 w-96 px-4 py-4 pr-12'/>
                    <a href='https://www.univ-fianarantsoa.mg/Rofia/magazine' target='_blank'>
                        <button className='absolute right-0 top-0 bottom-0 text-black px-5 py-3 bg-green-100'>
                            <FeedRounded />
                        </button>
                    </a>
                </div>
            </div>
            <hr/>
        </div>
    );
}

export default Footer;
