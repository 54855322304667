import React from 'react'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import logo1 from "../assets/images/Faritra_Vatovinany_Fitovinany.png";
import logo2 from "../assets/images/Atsimo_Atsinanana.png";
import logo3 from "../assets/images/BNI.png";
import logo4 from "../assets/images/Airtel.png";
import logo5 from "../assets/images/Orange.png";
import logo6 from "../assets/images/Telma.jpg";
import logo7 from "../assets/images/Slow_Food.png";
import logo8 from "../assets/images/Napoli_Frederico_II.png";
import logo9 from "../assets/images/Ecole_Polytechnique.png";
import logo10 from "../assets/images/Oiseau_Logo.png";
import logo11 from "../assets/images/WFP.png";
import logo12 from "../assets/images/HSD.png";
import logo13 from "../assets/images/BNITM.png";
import logo14 from "../assets/images/Stony_Brook.png";
import logo15 from "../assets/images/AUF.png";
import logo16 from "../assets/images/Ambassade_de_France_à_Madagascar.png";
import logo17 from "../assets/images/Erasmus+_Logo.png";
import logo18 from "../assets/images/INSA.png";
import logo19 from "../assets/images/Universite_de_Lyon.png";
import logo20 from "../assets/images/Inalco.png";
import logo21 from "../assets/images/Engees.png";
import logo22 from "../assets/images/Cyberlibris.png";
import logo23 from "../assets/images/Cirad.jpg";
import logo24 from "../assets/images/Arke_Up.png";
import logo25 from "../assets/images/Université_Gustave_Eiffel.png";
import logo26 from "../assets/images/Bordeau_Montaigne.png";
import logo27 from "../assets/images/IRD.png";
import logo28 from "../assets/images/Bordeau_Sciences_Agro.png";

const images = [
    logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10,
    logo11, logo12, logo13, logo14, logo15, logo16, logo17, logo18, logo19,
    logo20, logo21, logo22, logo23, logo24, logo25, logo26, logo27, logo28
];

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
};

function Carrousel() {
    return (
        <div className='w-full my-2'>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} className='flex justify-center items-center px-2'>
                        <img src={image} alt={`Slide ${index}`}
                             className='max-w-full h-48 object-contain'/>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default Carrousel
