import './App.css';
import Header from "./components/header";
import Home from "./views/home";
import Result from "./views/result";
import Registration from "./views/registration";
import Footer from "./components/footer";
import {Routes, Route} from 'react-router-dom';

function App() {
    return (
        <>
            <div className="App container mx-auto my-10">
                <Header />
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/result" element={<Result/>}/>
                    <Route path="/registration" element={<Registration/>}/>
                </Routes>
            </div>
            <Footer/>

        </>
    );
}

export default App;
