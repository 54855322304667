import axios from 'axios';

const api = axios.create({
    baseURL: 'https://backoffice.hiqaody.me/api',
});

api.interceptors.request.use(
    (config) => {
        const token = 'hiqaody!1510';
        if (token) {
            config.headers['X-API-KEY'] = token;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export default api;
