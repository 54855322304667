import React from 'react'

function Title() {
    return (
        <div>
            <h1 className='text-center font-bold text-7xl py-10' id='#accueil'>
                Rejoignez <span className='text-orange-300 font-bold text-7xl'>l'excellence</span> académique à <span
                className='text-orange-300 font-bold text-7xl'>l'Université de Fianarantsoa</span>
            </h1>
            <p className='text-center text-2xl'>
                Inscrivez-vous dès maintenant pour participer à nos concours et démarquez-vous grâce à votre talent et
                votre passion.
            </p>
        </div>
    )
}

export default Title;
