import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Search from '../components/search';
import Etablissement from '../components/etablissement';
import {InfoRounded} from "@mui/icons-material";

function Result() {
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const searchTerm = query.get('search');
    const [matchedResult, setMatchedResult] = useState(null);

    const result = {
        etudiant1: {
            numero: 3500256,
            nom: 'ANJARATIANA Faneva Yves Rolland',
            mention: 'Passable',
            serie: 'S',
            centre: 'Talatamaty Fianarantsoa'
        },
        etudiant2: {
            numero: 512004,
            nom: 'RAMAHEFARSON Alain Patrick',
            mention: 'Bien',
            serie: 'F3',
            centre: 'LTP Beravinna Fianarantsoa'
        },
        etudiant3: {
            numero: 3500258,
            nom: 'RANDRIANOMENJANAHARY Jacquinot',
            mention: 'Honorable',
            serie: 'D',
            centre: 'Ambalavao'
        },
        etudiant4: {
            numero: 3500258,
            nom: 'RAKOTO Jacquinot',
            mention: 'Honorable',
            serie: 'G1',
            centre: 'Ambalavao'
        }
    };

    useEffect(() => {
        // Your static data or fetched result should not be in the dependency array
        const checkResult = Object.values(result).find(
            (student) => student.nom.toLowerCase() === searchTerm?.toLowerCase()
        );
        setMatchedResult(checkResult);
    }, [searchTerm]); // Only depend on searchTerm


    const handleSearch = (newSearchTerm) => {
        navigate(`/result?search=${newSearchTerm}`);
    };

    const isResultsPage = location.pathname === '/result';

    return (
        <div className='container flex flex-col items-center relative'>
            <Search onSearch={handleSearch}/>
            {isResultsPage && searchTerm && matchedResult ? (
                <>
                    <div className='container-lg text-center bg-green-100 mt-10 p-10'>
                        <h1 className='text-7xl font-bold text-custom-green'>Félicitation pour l'obtention de votre
                            baccalauréat</h1>
                        <p className='text-2xl font-bold mt-3 mb-3'>AVEC</p>
                        <div className='flex justify-between bg-amber-400 px-4 py-8 rounded-t-2xl'>
                            <p className='text-xl'><span className='font-bold'>Numéro: </span>{matchedResult.numero}</p>
                            <p className='text-xl'><span className='font-bold'>Nom: </span>{matchedResult.nom}</p>
                            <p className='text-xl'><span className='font-bold'>Mention: </span>{matchedResult.mention}
                            </p>
                            <p className='text-xl'><span className='font-bold'>Série: </span>{matchedResult.serie}</p>
                            <p className='text-xl'><span className='font-bold'>Centre: </span>{matchedResult.centre}</p>
                        </div>
                    </div>
                    <div className='mt-10 w-full px-6'>
                        <p className='font-bold'>
                            Vous êtes maintenant éligible pour vous inscrire aux concours ou à la sélection de dossiers
                            au sein des établissements listés suivants :
                        </p>
                    </div>
                    <div className='w-full flex bg-green-50 mt-10'>
                        <div className='md:w-1/6 px-6 py-10 flex flex-col items-center'>
                            <InfoRounded sx={{ "fontSize": 150 }} className='text-amber-400'/>
                        </div>
                        <div className='md:w-5/6 px-6 py-10 flex flex-col justify-center'>
                            <h1>
                                Une fois que vous avez trouvé les établissements qui vous intéressent, suivez les
                                instructions pour soumettre votre dossier ou vous inscrire au concours. Nous vous
                                souhaitons bonne chance dans votre parcours universitaire !
                            </h1>
                        </div>
                    </div>
                    <Etablissement serie={matchedResult.serie}/>
                </>
            ) : (
                <div className=' w-full text-center bg-red-200 mt-10 p-10'>
                    <h1 className='text-7xl font-bold text-red-700'>Aucun résultat trouvé !</h1>

                </div>
            )}
        </div>
    );
}

export default Result;
