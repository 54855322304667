import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import MultiStepForm from '../components/multistepform';

function Registration() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const etablissementId = query.get('id');
    const etablissementName = query.get('name');

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
        document.body.classList.add('overflow-hidden');
    };

    const handleClose = () => {
        setOpen(false);
        document.body.classList.remove('overflow-hidden');
    };

    useEffect(() => {
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);

    return (
        <div className='container flex flex-col'>
            <h1 className='text-2xl font-bold my-10'>Dossier pour l'inscription à l'établissement
                : {etablissementName}</h1>
            <ul>
                <li className='list-disc text-xl mb-5'>
                    Un (01) reçu de versement de : CINQUANTE MILLE ARIARY (50.000 Ar) Valant droit d'inscription au
                    concours pour les candidats nationaux et CENT MILLE ARIARY (100.000 Ar) pour les candidats
                    étrangers;
                </li>
                <li className='list-disc text-xl mb-5'>
                    Une (01) Fiche de Renseignement remplie en bonne et due forme par le(la) candidat(e);
                </li>
                <li className='list-disc text-xl mb-5'>
                    Une (01) demande d'inscription à adresser à Monsieur le Directeur de l'Ecole de Management et
                    d'Innovation Technologique de l'Université de Fianarantsoa;
                </li>
                <li className='list-disc text-xl mb-5'>
                    Une (01) copie certifiée conforme du diplôme de Baccalauréat ou photocopie légalisée d'un relevé des
                    notes;
                </li>
                <li className='list-disc text-xl mb-5'>
                    Une (01) copie d'Acte d'Etat civil datant d'au moins un an;
                </li>
                <li className='list-disc text-xl mb-5'>
                    Deux (02) enveloppes timbrées (grand format) portant l'adresse exacte du(de la) candidat(e);
                </li>
                <li className='list-disc text-xl mb-5'>
                    Quatre (04) photos d'identités récentes et identiques, en couleur;
                </li>
            </ul>
            <button
                className='w-[233px] transform-translate-y-1/2 text-white font-bold px-6 py-4 rounded-[30px]'
                style={{backgroundColor: '#008D6C'}}
                onClick={handleOpen}
            >
                Envoyer ma candidature
            </button>

            <form action="">
                {open && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white p-8 rounded-lg relative">
                            <button
                                className="absolute top-2 right-2 text-gray-500 hover:text-red-500 text-4xl right-5"
                                onClick={handleClose}
                            >
                                &times;
                            </button>
                            <MultiStepForm etablissementName={etablissementName} etablissementId={etablissementId} handleClose={handleClose}/>
                        </div>
                    </div>
                )}
            </form>
        </div>
);
}

export default Registration;
