import React from 'react'


function Devise() {
    return (
        <div className='w-full bg-green-100 py-32 flex justify-around my-20'>
            <div className='mx-20'>
                <h2 className='text-4xl font-bold mb-4'>Valeurs</h2>
                <p className='text-2xl'>Excellence, Innovation et Développement</p>
            </div>
            <div className='mx-20'>
                <h2 className='text-4xl font-bold mb-4'>Slogan</h2>
                <p className='text-2xl'>Ensemble pour une Université et d’Innovation</p>
            </div>
            <div className='mx-20'>
                <h2 className='text-4xl font-bold mb-4'>Vision</h2>
                <p className='text-2xl'>Promouvoir une formation et une recherche scientifique de haut niveau,
                    innovante au service du développement de la nation </p>
            </div>
        </div>
    )
}

export default Devise
