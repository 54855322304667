import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from "../apiConfig/apiconfig";

function Etablissement({ serie }) {
    const location = useLocation();
    const navigate = useNavigate();
    const isResultsPage = location.pathname.includes('/result');
    const [etablissements, setEtablissements] = useState([]);
    const [groupedEtablissements, setGroupedEtablissements] = useState({});

    useEffect(() => {
        const fetchEtablissements = async () => {
            try {
                const response = isResultsPage && serie
                    ? await api.get(`/etablissements/getbyserie?serie=${serie}`)
                    : await api.get('/etablissements');

                const newData = response.data.map(etablissement => {
                    const photoUrl = `http://localhost:3002${etablissement.photo_etab}`;
                    console.log(`Photo URL for ${etablissement.nom_etab}: ${photoUrl}`); // Vérifier l'URL de chaque image
                    return {
                        ...etablissement,
                        photo_etab: photoUrl,
                        nom_type_etab: etablissement.typeEtablissement?.nom_type_etab || 'Type inconnu'
                    };
                });

                setEtablissements(newData);
            } catch (error) {
                console.error('Erreur lors de la récupération des établissements', error);
            }
        };

        fetchEtablissements();
    }, [isResultsPage, serie]);

    useEffect(() => {
        const groupByType = etablissements.reduce((group, etablissement) => {
            const { nom_type_etab } = etablissement;
            if (!group[nom_type_etab]) {
                group[nom_type_etab] = [];
            }
            group[nom_type_etab].push(etablissement);
            return group;
        }, {});
        setGroupedEtablissements(groupByType);
    }, [etablissements]);

    const handleClick = (etablissement) => {
        if (isResultsPage) {
            navigate(`/registration?id=${etablissement.id_etab}&name=${etablissement.nom_etab}`);
        } else {
            window.location.href = etablissement.url_etab;
        }
    };

    const handleImageError = (event) => {
        // Fallback si l'image ne charge pas
        event.target.src = 'http://localhost:3002/uploads/default_image.jpg';
    };

    return (
        <div className="w-full">
            <h1 className="text-3xl font-bold my-10 text-center">ETABLISSEMENTS</h1>
            <hr />
            {Object.keys(groupedEtablissements).map((type) => (
                <div key={type}>
                    <div id={type}>
                        <h2 className="text-3xl font-bold my-10 text-left">{type}</h2>
                        <div className="w-full flex flex-wrap p-4">
                            {groupedEtablissements[type].map((etablissement, index) => (
                                <div
                                    key={index}
                                    className="relative w-full lg:w-1/3 group cursor-pointer"
                                    onClick={() => handleClick(etablissement)}
                                >
                                    <img
                                        src={etablissement.photo_etab}
                                        alt={etablissement.nom_etab}
                                        onError={handleImageError} // Ajout d'un fallback en cas d'erreur de chargement de l'image
                                        className="w-full h-96 object-cover"
                                    />
                                    <div className="absolute inset-0 flex flex-col justify-end items-center bg-gradient-to-t from-black to-transparent text-white p-4">
                                        <h2 className="text-4xl font-bold mb-2 text-center">{etablissement.nom_etab}</h2>
                                        <p className="text-2xl text-center mb-2">{etablissement.nom_type_etab}</p>
                                        <p className="text-2xl text-blue-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                            {isResultsPage ? 'Postuler' : 'En savoir plus'}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Etablissement;