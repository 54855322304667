import React, {useState} from 'react';

function Search({onSearch}) {
    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchClick = () => {
        onSearch(searchTerm);
    };

    return (
        <div className='relative my-20'>
            <input
                type='search'
                className='w-[710px] h-[70px] border-2 rounded-[36px] pl-6 pr-[250px] py-3 text-lg placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                placeholder='Numéro du bacc ou nom complet'
                value={searchTerm}
                onChange={handleInputChange}
            />
            <button
                onClick={handleSearchClick}
                className='absolute right-2 w-[233px] top-1/2 transform -translate-y-1/2 text-white font-bold px-6 py-4 rounded-[30px]'
                style={{backgroundColor: '#008D6C'}}
            >
                Rechercher
            </button>
        </div>
    );
}

export default Search;
