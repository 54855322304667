import React, {useEffect, useState} from 'react';
import Swal from "sweetalert2";
import api from "../apiConfig/apiconfig";

function MultiStepForm({etablissementName, handleClose, etablissementId}) {
    const [step, setStep] = useState(1);
    const [doubleFiliere, setDoubleFiliere] = useState('');
    const [nomEtablissement, setNomEtablissement] = useState('');
    const [mention, setMention] = useState([]);

    useEffect(() => {
        api.get('/mention/getbyIdetab/' + etablissementId)
            .then(response => {
                console.log(response);
                setMention(response);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);
    const nextStep = () => {
        if (step < 4) {
            setStep(step + 1);
        }
    };

    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const handleSubmit = () => {
        Swal.fire({
            icon: "success",
            title: "Candidature envoyé avec succès",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 1500
        });
        handleClose();
    };

    return (
        <div className="w-[800px]">
            <h2 className="text-2xl font-bold mb-4">Formulaire d'inscription pour {etablissementName}</h2>
            <h1 className="block mb-5"><span className="text-red-500">(*)</span>: champs obligatoires</h1>
            <hr/>
            {step === 1 && <Step1
                doubleFiliere={doubleFiliere}
                setDoubleFiliere={setDoubleFiliere}
                nomEtablissement={nomEtablissement}
                setNomEtablissement={setNomEtablissement}
            />}
            {step === 2 && <Step2/>}
            {step === 3 && <Step3/>}
            {step === 4 && <Step4/>}
            <div className="mt-4 flex justify-between">
                <button
                    className="px-4 py-2 bg-gray-300 rounded-lg text-gray-800 font-semibold hover:bg-gray-400"
                    onClick={prevStep}
                    disabled={step === 1}
                >
                    Précédent
                </button>
                {step === 4 ? (
                    <button
                        className="px-4 py-2 bg-custom-green rounded-lg text-white font-semibold hover:bg-green-600"
                        onClick={handleSubmit}
                    >
                        Soumettre
                    </button>
                ) : (
                    <button
                        className="px-4 py-2 bg-custom-green rounded-lg text-white font-semibold hover:bg-blue-600"
                        onClick={nextStep}
                    >
                        Suivant
                    </button>
                )}
            </div>
        </div>
    );
}

function Step1() {
    return (
        <div className="grid grid-cols-2 gap-4">
            <div>
                <label htmlFor="">Mention <span className="text-red-500">*</span></label>
                <select
                    name=""
                    id=""
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                >
                    <option value="">--Veuillez choisir s'il vou plaît--</option>

                </select>
                <label htmlFor="">Centre de concours <span className="text-red-500">*</span></label>
                <select
                    name=""
                    id=""
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                >
                    <option value="">--Veuillez choisir s'il vou plaît--</option>
                    <option value="Fianarantsoa">Fianarantsoa</option>
                    <option value="Ambositra">Ambositra</option>
                    <option value="Antananarivo">Antananarivo</option>
                </select>
                <label htmlFor="">Noms <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Prénoms <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Nationalité <span className="text-red-500">*</span></label>
                <select
                    name=""
                    id=""
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                >
                    <option value="">--Veuillez choisir s'il vou plaît--</option>
                    <option value="Malagasy">Malagasy</option>
                    <option value="Etranger(ère)">Etranger(ère)</option>
                </select>
            </div>
            <div>
                <label htmlFor="">Date de naissance <span className="text-red-500">*</span></label>
                <input
                    type="date"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Lieu de naissance <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">CIN</label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Délivré le</label>
                <input
                    type="date"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Fait à</label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
            </div>
        </div>
    );
}

function Step2() {
    return (
        <div className="grid grid-cols-2 gap-4">
            <div>
                <label htmlFor="">Situation de famille <span className="text-red-500">*</span></label>
                <select
                    name=""
                    id=""
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                >
                    <option value="">--Veuillez choisir s'il vou plaît--</option>
                    <option value="">Marié(e)</option>
                    <option value="">Pacsé(e)</option>
                    <option value="">Divorcé(e)</option>
                    <option value="">Séparé(e)</option>
                    <option value="">Veuf(ve)</option>
                    <option value="">Célibataire</option>
                </select>
                <label htmlFor="">Adresse de l'étudiant <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Noms et prénoms du père</label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Noms et prénoms de la mère</label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Adresse des parents</label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
            </div>
            <div>
                <label htmlFor="">Téléphone <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Email</label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Profession du père</label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Profession de la mère</label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Téléphone des parents</label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
            </div>
        </div>
    );
}

function Step3() {
    return (
        <div className="grid grid-cols-4 gap-4">
            <div>
                <label htmlFor="">Années (Décroissante) <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
            </div>
            <div>
                <label htmlFor="">Classes <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
            </div>
            <div>
                <label htmlFor="">Etablissements <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
            </div>
            <div>
                <label htmlFor="">Résultats <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
            </div>
        </div>
    )
        ;
}

function Step4() {
    const [doubleFiliere, setDoubleFiliere] = useState('');
    const [nomEtablissement, setNomEtablissement] = useState('');
    return (
        <div className="grid grid-cols-2 gap-4">
            <div>
                <label htmlFor="">Numéro Bacc <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Série Bacc <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-11"
                />
                <label htmlFor="doubleFiliere">Double Filière :</label>
                <input
                    type="radio"
                    name="doubleFiliere"
                    className="ml-2 mb-10"
                    value="Oui"
                    checked={doubleFiliere === 'Oui'}
                    onChange={(e) => setDoubleFiliere(e.target.value)}
                /> Oui
                <input
                    type="radio"
                    name="doubleFiliere"
                    className="ml-2 mb-11"
                    value="Non"
                    checked={doubleFiliere === 'Non'}
                    onChange={(e) => setDoubleFiliere(e.target.value)}
                /> Non
                {doubleFiliere === 'Oui' && (
                    <div>
                        <label htmlFor="nomEtablissement">Nom de l'établissement :</label>
                        <input
                            type="text"
                            id="nomEtablissement"
                            value={nomEtablissement}
                            onChange={(e) => setNomEtablissement(e.target.value)}
                            className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                        />
                    </div>
                )}
            </div>
            <div>
                <label htmlFor="">Mention BACC <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Année BACC <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
                <label htmlFor="">Type BACC <span className="text-red-500">*</span></label>
                <input
                    type="text"
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4 h-10"
                />
            </div>
        </div>
    );
}

export default MultiStepForm;