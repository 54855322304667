import React from 'react'
import HDR from '../assets/images/HDR.png';


function President() {
    return (

    <div className='text-white text-center flex'>
        <div className='md:w-1/4 px-6 py-10 flex flex-col items-center rounded-l-[20px]'
             style={{backgroundColor: '#181D38'}}>
            <img src={HDR} className='max-w-full h-30 object-contain'/>
            <p className='text-xl font-bold'>HDR</p>
            <p className='text-xl'>HAJALALAINA Aimé Richard</p>
        </div>
        <div className='w-full md:w-3/4 bg-gray-50 px-6 py-10 flex flex-col justify-center rounded-r-[20px]'>
            <h2 className='text-black text-4xl font-bold mb-10 text-left'>Mots du Président</h2>
            <p className='text-black text-2xl text-left'>
                En tant que Président de l’Université de Fianarantsoa, j’ai
                un fort désir d’aider les universitaire à réussir dans la vie. Les nouveaux axes stratégiques
                adoptés par l’Université de Fianarantsoa sont orientés vers le développement, notamment le
                développement rural et l’innovation, avec un focus sur la professionnalisation et
                employabilité des étudiants. Face au problème du chômage rencotré par les jeunes
                diplômés.
            </p>
        </div>
    </div>
    )
}

export default President
